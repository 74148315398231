import type { JSX } from "react";
import Link from "next/link";

import type { NavElement } from "@gility/ui";
import { cn } from "@gility/lib";
import { WWW_APP_URL } from "@gility/lib/constants";
import { CompanyInfo, Container, FooterLink, Logo } from "@gility/ui";

export type FooterProps = JSX.IntrinsicElements["div"] & {
  color?: "light" | "dark";
  elements?: NavElement[];
};

export const Footer = ({ className, children, ...props }: FooterProps) => (
  <footer
    className={cn("relative bg-white", className)}
    aria-labelledby="footer-heading"
    {...props}>
    <Container className="py-6" fullwidth>
      <div className="items-middle flex flex-col gap-6 md:flex-row">
        <Link href="/dashboard" className="lg:mr-12">
          <Logo className="-mb-[5px] mt-[5px] h-8" />
        </Link>
        <CompanyInfo className="md:flex-grow" variant="learn" />
        <ul role="list" className="flex flex-row items-center gap-4">
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/privacy-policy`}
              variant="learn"
              icon={false}>
              Privacy Policy
            </FooterLink>
          </li>
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/termini-e-condizioni`}
              variant="learn"
              icon={false}>
              Termini e Condizioni
            </FooterLink>
          </li>
          <li>
            <FooterLink
              target="_blank"
              className="!leading-0 inline-block"
              href={`${WWW_APP_URL}/cookie-policy`}
              variant="learn"
              icon={false}>
              Cookie Policy
            </FooterLink>
          </li>
        </ul>
      </div>
    </Container>
    {children}
  </footer>
);
